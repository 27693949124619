footer.copyright {
	padding-bottom: 0 !important;
}

header.woocommerce-products-header {
	display: none !important
}

.single_add_to_cart_button_clone {
	font-family: IBM Plex Sans;
	font-weight: 600;
	letter-spacing: 0px;
	text-transform: none;
	float: left;
	width: calc(100% - 95px);
	height: 58px;
	margin-left: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

dl.tc-epo-metadata.variation>dt,
dl.tc-epo-metadata.variation>dd {
	display: none !important;
}

#main {
	background-color: white !important;
}

.jdgm-branding-footer,
.jdgm-rev__prod-info-wrapper {
	display: none !important
}

.jdgm-all-reviews-widget {
	max-width: 1360px !important
}

.single-product .jdgm-widget.jdgm-widge {
	max-width: 1360px !important
}

.flex-viewport {
	background-color: #f0f0f0 !important;
}

@media only screen and (max-width:39.9375em) {
	.modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0!important
	}
}

.gr-modal .close-modal {
	position: absolute;
	cursor: pointer;
	z-index: 999999;
	margin-top: -8px;
	top: 2px;
	right: 10px;
	opacity: 0
}

.gr-modal .close-modal svg {
	width: 1em;
	height: 1em
}

.gr-modal .modal-content {
	opacity: 0
}

.gr-modal.active {
	visibility: visible;
	opacity: 1
}

.gr-modal.active .modal-content {
	opacity: 1
}

.gr-modal.active .close-modal {
	opacity: 1
}

.gr-full-width-iframe {
	position: absolute;
	padding-left: 0.25em;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: 0;
	overflow: hidden;
}

.gr-modal-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: rgba(0, 0, 0, .6);
	opacity: 0;
	visibility: hidden
}

.gr-modal-overlay.active {
	opacity: 1;
	visibility: visible
}

.gr-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0 auto;
	background-color: #fff;
	width: 60em;
	max-width: 75rem;
	min-height: 40em;
	padding: 1rem;
	border-radius: 3px;
	opacity: 0;
	overflow-y: auto;
	visibility: hidden;
	box-shadow: 0 2px 10px rgba(0, 0, 0, .1)
}

#preview-container {
	width: 100%;
	text-align: center;
	margin-top: 5%;
	vertical-align: middle;
	height: 90%
}

#preview-container>img {
	max-width: 100%;
	max-height: 90%!important;
	height: auto;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
}

.modal {
	overflow: hidden;
	display: none;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

.customily-close-button {
	background: white;
	color: black;
	border-radius: 100%;
	margin: auto;
	width: 40px;
	height: 40px;
	padding-top: 8px
}

.preview-link {
	color: #3077d0!important;
	text-decoration: underline;
	font-size: 13px!important;
	font-weight: 600!important;
	;
}

.preview-link:hover,
.preview-link:active {
	opacity: 0.8;
}

.product_meta {
	display: none
}

section.related.products>h2 {
	text-align: center
}

section.related.products>ul {
	justify-content: center
}

.product-details-wrapper {
	padding-top: 20px;
}

.custom-product-reviews {
	margin-top:1.5em !important;
	padding-bottom:1em !important;
}



